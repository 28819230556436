<template>
  <v-form v-model="valid" ref="form" @submit.prevent="commit">
    <v-row no-gutters>
      <v-col>
        <FileInput
          title="Tirar foto do seu documento - FRENTE"
          v-model="form.request_doc"
          class="mb-4"
        >
          <v-icon left dark> mdi-camera </v-icon>Guia de solicitação
        </FileInput>
        <FileInput
          v-if="!isPaid"
          title="Carteira do convênio - Frente"
          v-model="form.card_front"
          class="mb-4"
        >
          <v-icon left dark> mdi-camera </v-icon>Carteira do convênio - Frente
        </FileInput>
        <FileInput
          v-if="!isPaid"
          title="Carteira do convênio - Verso"
          v-model="form.card_back"
          class="mb-4"
        >
          <v-icon left dark> mdi-camera </v-icon>Carteira do convênio - Verso
        </FileInput>
        <v-text-field
          label="Convênio"
          autofocus
          dense
          outlined
          disabled
          name="covenant"
          v-model="form.name"
        ></v-text-field>
        <v-text-field
          label="Plano"
          dense
          outlined
          disabled
          name="plan"
          v-model="form.plan"
        ></v-text-field>
        <div v-if="!isPaid">
          <v-text-field
            label="Validade da carteira"
            dense
            outlined
            name="validate"
            v-model="form.validate"
            v-mask.raw="'##/##/#####'"
            :rules="
              $rules.date(
                $store.state.checkin.required_fields
                  .checkin_covenant_card_validate_required
              )
            "
          ></v-text-field>
          <v-text-field
            label="Número da carteira"
            dense
            outlined
            name="card_number"
            v-model="form.card_number"
            :rules="
              $rules.max(
                20,
                $store.state.checkin.required_fields
                  .checkin_covenant_card_number_required
              )
            "
          ></v-text-field>
          <v-text-field
            label="Número da guia"
            dense
            outlined
            name="guide_number"
            v-model="form.guide_number"
            :rules="
              $rules.max(
                20,
                $store.state.checkin.required_fields
                  .checkin_covenant_guide_number_required
              )
            "
          ></v-text-field>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="pt-0">
        <v-btn
          block
          elevation="1"
          @click="$store.commit('checkin/updateForm', { step: 2 })"
          color="primary"
        >
          Anterior
        </v-btn>
      </v-col>
      <v-col class="pt-0">
        <v-btn block elevation="1" type="submit" color="primary">
          Próximo
        </v-btn>
      </v-col>
    </v-row>
  </v-form>
</template>
<script type="text/javascript">
import Api from '@/resources/NajaSocialApi'
import CheckinStateEnum from '@/enumerations/checkin_state_enum'
import FileInput from '@/components/FileInput'
import { mapState, mapGetters } from 'vuex'
export default {
  name: 'CheckinExams',
  components: { FileInput },
  computed: {
    ...mapState({
      schedules: state => state.checkin.schedules,
    }),

    ...mapGetters({
      selectedSchedule: 'checkin/selectedSchedule',
      selectedCovenant: 'checkin/selectedCovenant',
      rules: 'checkin/rules',
    }),

    showErrors() {
      return this.errorMessages.length > 0
    },

    isPaid() {
      return this.selectedCovenant.con_stapag
    },
  },

  data() {
    const form = Object.assign({}, this.$store.state.checkin.form.covenant)

    // const selectedSchedule = this.$store.getters['checkin/selectedSchedule']
    // const selectedCovenant = this.$store.getters['checkin/selectedCovenant']
    return {
      valid: false,
      loading: false,
      form: form,
    }
  },

  methods: {
    _validFiles() {
      const errors = []
      if (
        this.$store.state.checkin.required_fields
          .checkin_covenant_request_doc_required &&
        !(this.form.request_doc instanceof File)
      ) {
        errors.push('Guia de solicitação é obrigatório')
      }

      if (
        this.$store.state.checkin.required_fields
          .checkin_covenant_card_front_required &&
        !this.isPaid &&
        !(this.form.card_front instanceof File)
      ) {
        errors.push('Carteira do convênio - Frente é obrigatório')
      }

      if (
        this.$store.state.checkin.required_fields
          .checkin_covenant_card_back_required &&
        !this.isPaid &&
        !(this.form.card_back instanceof File)
      ) {
        errors.push('Carteira do convênio - Verso é obrigatório')
      }

      if (errors.length > 0) {
        errors.forEach(e => this.$toast.show(e, 'warn'))
        return false
      }
      return true
    },

    async commit() {
      this.$refs.form.validate()
      if (this.valid && this._validFiles()) {
        await Api.checkin.save(this.$store.state.checkin.form.id, {
          checkin: { state: CheckinStateEnum.COVENANTS },
        })
        this.$store.commit('checkin/updateForm', {
          covenant: this.form,
          step: 4,
        })
      }
    },
  },
}
</script>
