<template>
  <div>
    <v-dialog v-model="upload.show" hide-overlay width="300">
      <v-card color="primary" dark>
        <v-card-text>
          {{ upload.name }}
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
            :value="upload.percent"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-row>
      <v-col class="py-0">
        Eu, {{ patient.name }}, portador do CPF {{ patient.cpf }}, declaro total
        responsabilidade pela veracidade das informações aqui apresentadas em
        observância ao Código Penal Brasileiro.
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-checkbox
          class="mt-0"
          name="terms"
          hide-details
          v-model="accepted_terms"
          label="Confirmo as informações acima."
        ></v-checkbox>
        <v-checkbox
          name="present"
          hide-details
          v-model="present"
          label="Paciente já se encontra na clínica."
        ></v-checkbox>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-btn
          block
          elevation="1"
          @click="$store.commit('checkin/updateForm', { step: 3 })"
          color="primary"
        >
          Anterior
        </v-btn>
      </v-col>
      <v-col>
        <v-btn
          block
          elevation="1"
          :loading="loading"
          @click="commit"
          color="primary"
        >
          Próximo
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>
<script type="text/javascript">
import Api from '@/resources/NajaSocialApi'
import CheckinStateEnum from '@/enumerations/checkin_state_enum'

import { mapState } from 'vuex'
import $ from 'jquery'
export default {
  name: 'CheckinExams',
  computed: {
    ...mapState({
      patient: state => state.checkin.form.patient,
    }),
  },

  data() {
    return {
      loading: false,
      present: null,
      accepted_terms: null,
      upload: {
        show: false,
        name: null,
        percent: 0,
      },
    }
  },

  methods: {
    sendFile(attachment) {
      let formData = new FormData()
      formData.append('checkin_file[kind]', attachment.kind)
      formData.append(
        'checkin_file[checkin_id]',
        this.$store.state.checkin.form.id
      )
      formData.append('checkin_file[attachment]', attachment.file)

      return $.ajax({
        xhr: () => {
          let xhr = $.ajaxSettings.xhr()
          xhr.upload.onprogress = evt => {
            const percent = (evt.loaded / evt.total) * 100
            this.upload.percent = percent
            // this.$refs.progress.setPercent(attachment.kind, percent)
          }
          return xhr
        },
        beforeSend: request => {
          request.setRequestHeader('Naja-Api-Key', Api.accessToken)
          this.upload.name = attachment.file.name
          this.upload.show = true
        },
        complete: () => (this.upload.show = false),
        url: `${Api.baseURL}/checkin_files`,
        data: formData,
        processData: false,
        contentType: false,
        enctype: 'multipart/form-data',
        type: 'POST',
      })
    },

    async _sendFiles() {
      let checkin = this.$store.state.checkin.form
      let attachments = []
      let successfulCheckinFiles = []

      if (checkin.covenant.card_front instanceof File) {
        attachments.push({
          kind: 'card_front',
          file: checkin.covenant.card_front,
        })
      }
      if (checkin.covenant.card_back instanceof File) {
        attachments.push({
          kind: 'card_back',
          file: checkin.covenant.card_back,
        })
      }
      if (checkin.covenant.request_doc instanceof File) {
        attachments.push({
          kind: 'request_doc',
          file: checkin.covenant.request_doc,
        })
      }
      if (checkin.patient.doc_front instanceof File) {
        attachments.push({ kind: 'doc_front', file: checkin.patient.doc_front })
      }
      if (checkin.patient.doc_back instanceof File) {
        attachments.push({ kind: 'doc_back', file: checkin.patient.doc_back })
      }

      // set items to progress load
      let items = attachments.map(a => {
        return { key: a.kind }
      })
      items.push({ key: 'checkin' })
      // this.$refs.progress.setItems(items)

      const requests = attachments.map(async attachment => {
        try {
          const data = await this.sendFile(attachment)
          successfulCheckinFiles.push(data.checkin_file)
        } catch (error) {
          // this.$refs.progress.setError(attachment.kind)
          return false
        }
      })

      const results = await Promise.all(requests)
      if (results.includes(false)) {
        return false
      } else {
        return successfulCheckinFiles
      }
    },

    _raw(value) {
      return value == null ? null : value.replace(/[^\d]+/g, '')
    },

    _getFormatedDate(date) {
      let momentDate = this.$moment(date, 'DD/MM/YYYY')
      return momentDate.isValid() ? momentDate.format('YYYYMMDD') : null
    },

    _mountPreserviceData(attachments = []) {
      const checkin = this.$store.state.checkin.form
      return {
        checkin: {
          params: {
            tipopreatendimento: 1, // Fixed,
            origem: 1, // TODO get origin of request, 1 to web and 2 to mobile
            codigoagenda: checkin.schedule,
            numerocarteira: checkin.covenant.card_number || null,
            validadecarteira: this._getFormatedDate(checkin.covenant.validate),
            numeroguia: checkin.covenant.guide_number || null,
            pacientejachegou: this.present,
            paciente: {
              codigo: checkin.patient.code,
              nome: checkin.patient.name,
              endlogradouto: checkin.patient.address,
              endnumero: checkin.patient.address_number,
              endcomplemento: checkin.patient.complement,
              bairro: checkin.patient.neighborhood,
              tel1ddd: checkin.patient.phone_number
                ? this._raw(checkin.patient.phone_number).substring(0, 2)
                : null,
              tel1numero: checkin.patient.phone_number
                ? this._raw(checkin.patient.phone_number).substring(2)
                : null,
              tel2ddd: checkin.patient.phone_number_two
                ? this._raw(checkin.patient.phone_number_two).substring(0, 2)
                : null,
              tel2numero: checkin.patient.phone_number_two
                ? this._raw(checkin.patient.phone_number_two).substring(2)
                : null,
              codcodibge: checkin.patient.city_ibge_code,
              email: checkin.patient.email,
              dtnascimento: this._getFormatedDate(checkin.patient.birthday),
              codigosexo: checkin.patient.gender,
              codigoestadocivil: checkin.patient.civil_status,
              racacor: checkin.patient.breed,
              rg: this._raw(checkin.patient.rg),
              cpf: this._raw(checkin.patient.cpf),
            },
            anexos: attachments,
          }, // params
        }, // checkin
      } // data
    },

    async commit() {
      if (this.accepted_terms) {
        this.loading = true

        const attachments = await this._sendFiles()
        if (attachments) {
          // update checkin status
          await Api.checkin.save(this.$store.state.checkin.form.id, {
            checkin: { state: CheckinStateEnum.FINISHED },
          })

          const payload = this._mountPreserviceData(
            attachments.map(x => {
              return { codigotipoanexo: x.naja_code_to_kind, link: x.url }
            })
          )

          Api.createPreservice({ data: payload })
            .then(() => {
              this.$store.commit('checkin/updateForm', { step: 5 })
              // success = true
            })
            .catch(error => {
              if (error.response) {
                if (error.response.status == 500) {
                  this.$toast.show('Erro fatal', 'error')
                } else {
                  error.response.data.errors.forEach(errorMsg => {
                    this.$toast.show(errorMsg, 'error')
                  })
                }
              } else {
                throw error
              }
            })
            .finally(() => {
              this.loading = false
            })
        } else {
          this.loading = false
          this.$toast.show('Erro ao enviar anexos', 'warn')
        }
      } else {
        this.$toast.show('Para continuar aceite os termos', 'warn')
      }
    },
  },
}
</script>
