<template lang="html">
  <div>
    <input
      @change="setFile"
      type="file"
      accept="application/pdf,image/*"
      ref="fileInput"
      style="display: none"
    />
    <v-btn
      elevation="1"
      color="primary"
      block
      :title="$attrs.title"
      @click="$refs.fileInput.click()"
    >
      <slot></slot>
    </v-btn>
    <v-btn
      block
      class="mt-4"
      v-if="fileName"
      outlined
      color="primary"
      @click="removeFile"
    >
      {{ fileName }}
      <v-icon right dark> mdi-delete </v-icon>
    </v-btn>
  </div>
</template>

<script>
export default {
  name: 'FileInput',
  props: ['text', 'icon', 'value'],

  // props: {
  //   text: String,
  //   icon: String,
  //   value: {
  //     type: [String, File, Object],
  //     default: null,
  //   },
  // },

  data() {
    return {
      loading: false,
      // fileName: null,
    }
  },

  // mounted() {
  //   this._setInitFilename()
  // },

  computed: {
    fileName() {
      if (this.value) {
        if (typeof this.value === 'string') {
          return this.value
        } else {
          return this.value.name
        }
      }
      return null
    },
  },

  methods: {
    _setInitFilename() {
      const fileObj = this.$refs.fileInput.value
      if (fileObj) {
        this.fileName = this.$refs.fileInput.name
      }
    },
    setFile(evt) {
      console.log('setFile')
      this.$emit('input', evt.target.files[0])
      // this.fileName = evt.target.files[0].name
    },

    removeFile() {
      this.$emit('input', null)
      // this.$refs.fileInput.value = ''
      // this.fileName = null
    },
  },
}
</script>

<style lang="css" scoped></style>
