import { render, staticRenderFns } from "./FileInput.vue?vue&type=template&id=81aaf668&scoped=true&lang=html&"
import script from "./FileInput.vue?vue&type=script&lang=js&"
export * from "./FileInput.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "81aaf668",
  null
  
)

export default component.exports