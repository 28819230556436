<template id="checkin-page">
  <div>
    <LoadingContainer v-if="loading" />
    <Identification v-else-if="step == 0"></Identification>
    <v-row align="center" justify="center" class="mt-8 px-8 steps">
      <v-col sm="6" cols="12">
        <Patient v-if="step == 1" />
        <Exams v-if="step == 2"></Exams>
        <Covenants v-if="step == 3"></Covenants>
        <Confirmation v-if="step == 4"></Confirmation>
        <Final v-if="step == 5"></Final>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Identification from './steps/identification.vue'
import Patient from './steps/patient.vue'
import Exams from './steps/exams.vue'
import Covenants from './steps/covenants.vue'
import Confirmation from './steps/confirmation.vue'
import LoadingContainer from '@/components/LoadingContainer'
import Final from './steps/final.vue'
import { mapState } from 'vuex'

export default {
  data() {
    return {
      loading: false,
      errorMessage: 'Nenhum agendamento encontrado',
    }
  },

  async created() {
    if (!this.$store.state.data.organization.allows_checkin) {
      this.errorMessage = 'Checkin online desabilitado para organização'
      this.$store.commit('checkin/updateForm', { step: 99 })
      return
    }
  },

  computed: {
    ...mapState({
      step: state => state.checkin.form.step,
    }),
  },

  components: {
    Identification,
    Patient,
    Exams,
    Covenants,
    Confirmation,
    Final,
    LoadingContainer,
  },
  name: 'checkin',
}
</script>
