<template>
  <div>
    <v-row>
      <v-col class="py-0">
        <p>Check-in finalizado</p>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card class="mx-auto">
          <v-toolbar color="primary" dark>
            <v-toolbar-title>
              <div class="date">
                <span class="day">{{ getDay(schedule.AEX_Data) }}</span>
                <div class="details">
                  <span class="month">
                    de {{ getMonth(schedule.AEX_Data) }}
                  </span>
                  <span v-if="getHour(schedule)" class="hour">
                    às {{ getHour(schedule) }}
                  </span>
                </div>
              </div>
            </v-toolbar-title>
          </v-toolbar>

          <v-card-text class="text--primary">
            <v-row>
              <v-col cols="12">
                <div class="font-weight-medium">Unidade</div>
                <div>{{ schedule.Empresa.emp_nomfan }}</div>
              </v-col>
              <v-col cols="12">
                <div class="font-weight-medium">Paciente</div>
                <div>{{ checkin.patient.name }}</div>
              </v-col>
              <v-col cols="12">
                <div class="font-weight-medium">Exame</div>
                <div
                  v-for="(exam, index) in schedule.examesAgendados"
                  :key="index"
                >
                  {{ exam.procedimento.pro_nome }}
                </div>
              </v-col>
              <v-col cols="12" v-if="configs.final_text != null">
                <div class="font-weight-medium">Observações</div>
                <div>
                  {{ configs.final_text }}
                </div>
              </v-col>
              <v-divider />
              <v-col cols="12">
                <v-btn color="primary" block @click.prevent="redirect">
                  Sair
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script type="text/javascript">
// import Api from '@/resources/NajaSocialApi'
import { mapState } from 'vuex'

export default {
  name: 'CheckinFinal',
  computed: {
    ...mapState({
      checkin: state => state.checkin.form,
      configs: state => state.checkin.configs,
      schedule: state =>
        state.checkin.schedules.find(schedule => {
          return schedule.AEX_Isn == state.checkin.form.schedule
        }),
    }),
  },

  methods: {
    redirect() {
      this.$store.dispatch('checkin/logout')
    },

    getDay(date) {
      return this.$moment(date, 'DD/MM/YYYY').format('DD')
    },

    getMonth(date) {
      return this.$moment(date, 'DD/MM/YYYY').format('MMMM')
    },

    getHour(schedule) {
      let hours = schedule.examesAgendados.map(exam => {
        return exam.PAX_HoraSeq
      })
      let hour = hours.sort()[0]
      return this.$moment(hour, 'HHmm').format('HH:mm')
    },
  },
}
</script>
<style lang="scss" scoped>
.date {
  display: flex;
  align-items: center;
  .day {
    font-size: 2rem;
    margin-right: 1rem;
  }
  .details {
    display: flex;
    flex-direction: column;
  }
}
</style>
