<template>
  <v-form v-model="valid" ref="form">
    <v-row>
      <v-col>
        <FileInput
          title="Tirar foto do seu documento - FRENTE"
          v-model="form.doc_front"
        >
          <v-icon left dark> mdi-camera </v-icon>Documento - FRENTE
        </FileInput>
      </v-col>
      <v-col>
        <FileInput
          title="Tirar foto do seu documento - FRENTE"
          v-model="form.doc_back"
        >
          <v-icon left dark> mdi-camera </v-icon>Documento - VERSO
        </FileInput>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="pb-0">
        <v-text-field
          label="Nome do paciente"
          autofocus
          dense
          outlined
          :disabled="
            !$store.state.checkin.editable_fields.checkin_name_editable
          "
          name="name"
          v-model="form.name"
          :rules="$rules.required"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="py-0">
        <v-text-field
          label="Data de nascimento"
          dense
          outlined
          name="birthday"
          :rules="
            $rules.date(
              $store.state.checkin.required_fields
                .checkin_patient_birthday_required
            )
          "
          v-model="form.birthday"
          v-mask.raw="'##/##/#####'"
        ></v-text-field>
      </v-col>
      <v-col class="py-0">
        <v-select
          label="Sexo"
          dense
          :items="sexOptions"
          item-value="sxo_isn"
          item-text="sxo_dsc"
          outlined
          :rules="rules.checkin_patient_gender_required"
          name="sex"
          v-model="form.sex"
        ></v-select>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="py-0">
        <v-select
          label="Estado Civil"
          dense
          outlined
          :items="civilOptions"
          item-value="etc_isn"
          item-text="etc_dsc"
          name="birthday"
          :rules="rules.checkin_patient_civil_status_required"
          v-model="form.civil_status"
        ></v-select>
      </v-col>
      <v-col class="py-0">
        <v-select
          label="Raça"
          dense
          outlined
          name="sex"
          :items="breedOptions"
          item-value="racacor_cod"
          item-text="racacor_nome"
          :rules="rules.checkin_patient_breed_required"
          v-model="form.breed"
        ></v-select>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="py-0">
        <v-text-field
          label="Telefone 1"
          dense
          outlined
          name="cellphone"
          v-mask.raw="'(##) #### #####'"
          :rules="
            $rules.phone(
              $store.state.checkin.required_fields
                .checkin_patient_phone_number_required
            )
          "
          checkin_patient_breed_required
          v-model="form.phone_number"
        ></v-text-field>
      </v-col>
      <v-col class="py-0">
        <v-text-field
          label="Telefone 2"
          dense
          outlined
          name="phone"
          v-mask.raw="'(##) #### #####'"
          :rules="
            $rules.phone(
              $store.state.checkin.required_fields
                .checkin_patient_phone_number_two_required
            )
          "
          v-model="form.phone_number_2"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="py-0">
        <v-text-field
          label="E-mail"
          dense
          outlined
          name="email"
          type="email"
          :rules="
            $rules.email(
              $store.state.checkin.required_fields
                .checkin_patient_email_required
            )
          "
          v-model="form.email"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <div class="text--h2">Documentos</div>
        <v-divider />
      </v-col>
    </v-row>
    <v-row>
      <v-col class="py-0">
        <v-text-field
          label="CPF"
          dense
          outlined
          name="cpf"
          v-model="form.cpf"
          :disabled="!$store.state.checkin.editable_fields.checkin_cpf_editable"
          :rules="$rules.cpf(true)"
          v-mask.raw="'###.###.###-##'"
        ></v-text-field>
      </v-col>
      <v-col class="py-0">
        <v-text-field
          label="RG"
          dense
          outlined
          name="rg"
          :rules="rules.checkin_patient_rg_required"
          v-model="form.rg"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="py-0" cols="4">
        <v-text-field
          label="CEP"
          dense
          outlined
          name="cep"
          v-mask.raw="'##.###-###'"
          :rules="rules.checkin_patient_cep_required"
          v-model="form.cep"
        ></v-text-field>
      </v-col>
      <v-col class="py-0" cols="6">
        <v-text-field
          label="Endereço"
          dense
          outlined
          name="address"
          :rules="rules.checkin_patient_address_required"
          v-model="form.address"
        ></v-text-field>
      </v-col>
      <v-col class="py-0" cols="2">
        <v-text-field
          label="Número"
          dense
          outlined
          :rules="rules.checkin_patient_address_number_required"
          name="address_number"
          type="number"
          v-model="form.address_number"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="py-0">
        <v-text-field
          label="Complemento"
          dense
          outlined
          name="cpf"
          :rules="rules.checkin_patient_complement_required"
          v-model="form.complement"
        ></v-text-field>
      </v-col>
      <v-col class="py-0">
        <v-text-field
          label="Bairro"
          dense
          outlined
          name="neighborhood"
          :rules="rules.checkin_patient_neighborhood_required"
          v-model="form.neighborhood"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="py-0">
        <CityInput
          v-model="form.city_ibge_code"
          :defaultValue="$store.state.checkin.form.patient.city_ibge_code"
          :defaultText="$store.state.checkin.form.patient.city"
          :rules="rules.checkin_patient_city_required"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-btn
          block
          elevation="1"
          @click="$store.commit('checkin/updateForm', { step: 0 })"
          color="primary"
        >
          Anterior
        </v-btn>
      </v-col>
      <v-col>
        <v-btn block elevation="1" @click="commit" color="primary">
          Próximo
        </v-btn>
      </v-col>
    </v-row>
  </v-form>
</template>
<script type="text/javascript">
import { mapState, mapGetters } from 'vuex'
import CityInput from '@/components/CityInput'
import FileInput from '@/components/FileInput'
export default {
  name: 'CheckinPatient',
  components: { CityInput, FileInput },

  computed: {
    ...mapGetters({
      rules: 'checkin/rules',
    }),
    ...mapState({
      // patient: state => state.checkin.form.patient,
      civilOptions: state =>
        state.checkin.hits.civil.sort((a, b) =>
          a.etc_dsc > b.etc_dsc ? 1 : -1
        ),
      sexOptions: state =>
        state.checkin.hits.sex.sort((a, b) => (a.sxo_dsc > b.sxo_dsc ? 1 : -1)),
      requiredFields: state =>
        state.checkin.hits.civil.sort((a, b) =>
          a.etc_dsc > b.etc_dsc ? 1 : -1
        ),
      breedOptions: state =>
        state.checkin.hits.breed.sort((a, b) =>
          a.racacor_nome > b.racacor_nome ? 1 : -1
        ),
    }),
  },

  data() {
    const form = Object.assign({}, this.$store.state.checkin.form.patient)
    return {
      valid: false,
      loading: false,
      items: [],
      citySearch: null,
      cityLoading: null,
      form: form,
    }
  },

  methods: {
    _validFiles() {
      const errors = []
      if (
        this.$store.state.checkin.required_fields
          .checkin_patient_doc_front_required &&
        !(this.form.doc_front instanceof File)
      ) {
        errors.push('Documento - FRENTE é obrigatório')
      }

      if (
        this.$store.state.checkin.required_fields
          .checkin_patient_doc_back_required &&
        !(this.form.doc_back instanceof File)
      ) {
        errors.push('Documento - VERSO é obrigatório')
      }

      if (errors.length > 0) {
        errors.forEach(e => this.$toast.show(e, 'warn'))
        return false
      }
      return true
    },

    async commit() {
      this.$refs.form.validate()

      if (this.valid && this._validFiles()) {
        this.loading = true
        const payload = {
          patient: this.form,
          step: 2,
        }

        if (this.$route.query.schedule) {
          payload.schedule = this.$route.query.schedule
        }

        this.$store.commit('checkin/updateForm', payload)
      }
    },
  },
}
</script>
