<template>
  <v-form
    @submit.prevent="signin"
    class="text-center"
    v-model="valid"
    ref="form"
  >
    <img alt="Logo" class="client-logo" :src="organization.logo" />
    <div :class="[{ 'mx-4': $vuetify.breakpoint.mobile }, 'login-info']">
      Informe seu CPF e data de nascimento para fazer o seu check-in online
    </div>
    <div style="max-width: 330px; margin: 0 auto">
      <v-text-field
        label="Digite seu CPF"
        autofocus
        dense
        outlined
        name="username"
        v-model="form.cpf"
        :rules="$rules.required"
        v-mask.raw="'###.###.###-##'"
      ></v-text-field>
      <v-text-field
        v-model="form.birthday"
        :rules="$rules.required"
        name="birthday"
        dense
        v-mask.raw="'##/##/####'"
        label="Digite sua data de nascimento"
        outlined
      >
      </v-text-field>

      <v-btn
        block
        type="submit"
        :loading="loading"
        elevation="1"
        color="primary"
      >
        Continuar
      </v-btn>
      <img alt="Logo" class="naja-logo" src="@/assets/naja_logo_login.png" />
    </div>
  </v-form>
</template>
<script type="text/javascript">
import Api from '@/resources/NajaSocialApi'
import CheckinStateEnum from '@/enumerations/checkin_state_enum'
import { mapState } from 'vuex'

export default {
  name: 'CheckinIdentification',
  computed: {
    ...mapState({
      organization: state => state.data.organization,
      termosPortal: state => state.data.organization.termos_portal,
    }),

    requiresTerms() {
      return this.termosPortal.id != null
    },
  },

  data() {
    return {
      loading: false,
      valid: false,
      form: {
        cpf: '',
        birthday: '',
        terms: false,
      },
    }
  },

  methods: {
    async signin() {
      this.$refs.form.validate()

      if (this.valid) {
        this.loading = true

        let params = {}
        if (this.form.cpf != '') {
          params.cpf = this.form.cpf.replace(/\D/g, '')
        }
        if (this.form.birthday != '') {
          params.data_nasc = this.$moment(
            this.form.birthday,
            'DD/MM/YYYY'
          ).format('YYYYMMDD')
        }

        try {
          await this.$store.dispatch('checkin/loadSchedules', params)

          if (this.$store.state.checkin.schedules.length > 0) {
            this.$store.commit('checkin/updateForm', {
              step: 1,
              termsAccepted: this.form.terms,
            })
          } else {
            this.$toast.show('Nenhum agendamento encontrado', 'error')
          }
        } catch (error) {
          if (error.response && error.response.data.error) {
            this.$toast.show(error.response.data.error, 'error')
          } else {
            this.$toast.show('Ocorreu um erro', 'error')
            throw error
          }
        } finally {
          this.loading = false
        }
      }
    },
  },
}
</script>
<style scoped lang="scss">
.checkin {
  background: white;
}
.login-form {
  form {
    margin-left: auto;
    margin-right: auto;
    max-width: 380px;
  }

  form.reset-password-form {
    max-width: 400px;
  }
}

.login-info {
  text-align: left;
  border-radius: 1rem;
  border: 1px solid #f58220;
  background: #fceccd;
  padding: 1rem;
  color: #585348;
  margin-bottom: 3rem;
  font-size: 0.875rem;
}

.custom-client-bg {
  min-height: 100vh;
  // overflow: scroll;
}

.naja-logo {
  height: 3rem;
  margin: 0 auto;
  display: block;
  margin-top: 6rem;
  margin-bottom: 1rem;
}

.client-logo {
  max-height: 5rem;
  margin-bottom: 2rem;
  margin-top: 2rem;
}
</style>
