<template lang="html">
  <v-autocomplete
    v-bind="$attrs"
    v-on="$listeners"
    label="Cidade"
    dense
    outlined
    :loading="loading"
    @update:search-input="search"
    :items="items"
    item-text="city_formatted"
    item-value="ibge_code"
    name="city"
    hide-no-data
    clearable
  ></v-autocomplete>
</template>

<script>
import Api from '@/resources/NajaSocialApi'
import debounce from 'debounce'
export default {
  name: 'CityInput',
  props: ['defaultValue', 'defaultText'],
  data() {
    return {
      loading: false,
      // search: null,
      items: [
        {
          city_formatted: this.defaultText,
          ibge_code: this.defaultValue,
        },
      ],
    }
  },

  created() {
    if (this.defaultValue && this.defaultText) {
      this.items.push()
    }
  },

  // watch: {
  //   search(value) {
  //     if (!value) {
  //       return
  //     }
  //     // Debounce the input and wait for a pause of at
  //     // least 200 milliseconds. This can be changed to
  //     // suit your needs.
  //     debounce(this.makeSearch, 200)(value)
  //   },
  // },

  methods: {
    search(value) {
      if (!value) {
        return
      }

      // Debounce the input and wait for a pause of at
      // least 200 milliseconds. This can be changed to
      // suit your needs.
      debounce(this._makeSearch, 200)(value)
    },

    _makeSearch(search) {
      // fix recursive search bug
      if (this.$el.querySelector('input').value == search) {
        return
      }

      // Handle empty value
      if (!search) {
        this.items = []
      }

      // Items have already been requested
      if (this.loading) {
        return
      }

      this.loading = true

      // search = search.split('-')[0]

      const query = {
        q: {
          name_start: search,
        },
      }

      Api.cities(query)
        .then(async response => {
          this.items = response.data.cities
          // this.$store.commit('updateUser', response.data)
          // await this.$store.dispatch('getUserInfo')
          //
          // if (this.$store.state.data.user.role == 'patient') {
          //   this.$router.push({ name: 'home' })
          // } else {
          //   this.$router.push({ name: 'exams' })
          // }
        })
        // .catch(() => {
        //   this.$toast.show('Login inválido', 'error')
        // })
        .finally(() => {
          this.loading = false
        })
    },
  },
}
</script>

<style lang="css" scoped></style>
