<template>
  <div>
    <v-row v-for="(schedule, index) in schedules" :key="index">
      <v-col>
        <v-card class="mx-auto">
          <v-toolbar color="primary" dark>
            <v-toolbar-title>
              <div class="date">
                <span class="day">{{ getDay(schedule.AEX_Data) }}</span>
                <div class="details">
                  <span class="month">
                    de {{ getMonth(schedule.AEX_Data) }}
                  </span>
                  <span v-if="getHour(schedule)" class="hour">
                    às {{ getHour(schedule) }}
                  </span>
                </div>
              </div>
            </v-toolbar-title>

            <v-spacer></v-spacer>

            <v-checkbox
              v-model="selectedSchedule"
              :value="schedule.AEX_Isn"
              hide-details
            ></v-checkbox>
          </v-toolbar>

          <v-card-text class="primary--text">
            <v-row>
              <v-col>
                <div class="font-weight-medium">Exame</div>
                <div
                  v-for="(exam, exameIndex) in schedule.examesAgendados"
                  :key="exameIndex"
                >
                  {{
                    exam.procedimento.cpo_nome == null
                      ? exam.procedimento.pro_nome
                      : `${exam.procedimento.pro_nome} (${exam.procedimento.cpo_nome})`
                  }}
                </div>
              </v-col>
              <v-col>
                <div class="font-weight-bold">Unidade</div>
                <div>{{ schedule.Empresa.emp_nomfan }}</div>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-btn
          block
          elevation="1"
          @click="$store.commit('checkin/updateForm', { step: 1 })"
          color="primary"
        >
          Anterior
        </v-btn>
      </v-col>
      <v-col>
        <v-btn
          block
          elevation="1"
          @click="commit"
          :loading="loading"
          color="primary"
        >
          Próximo
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>
<script type="text/javascript">
import Api from '@/resources/NajaSocialApi'
import { mapState } from 'vuex'
import CheckinStateEnum from '@/enumerations/checkin_state_enum'
export default {
  name: 'CheckinExams',
  computed: {
    ...mapState({
      schedules: state => state.checkin.schedules,
    }),

    showErrors() {
      return this.errorMessages.length > 0
    },
  },

  mounted() {
    this._loadData()
  },

  data() {
    return {
      loading: false,
      selectedSchedule: null,
      errorMessages: [],
    }
  },

  methods: {
    async commit() {
      const isValid = this.selectedSchedule != null
      if (isValid) {
        try {
          this.loading = true

          const checkinPayload = {
            checkin: {
              schedule_naja_code: this.selectedSchedule,
              state: CheckinStateEnum.INITIALIZED,
            },
          }

          const queryPayload = {
            q: {
              schedule_naja_code_eq: this.selectedSchedule,
            },
          }

          const searchResponse = await Api.checkin.search(queryPayload)
          let checkinId = null

          if (searchResponse.data.checkins.length > 0) {
            // update checkin
            checkinId = searchResponse.data.checkins[0].id
            await Api.checkin.save(checkinId, checkinPayload)
          } else {
            // create checkin
            const checkinResponse = await Api.checkin.save(null, checkinPayload)
            checkinId = checkinResponse.data.checkin.id
          }

          // update form
          const schedule = this.schedules.find(
            x => x.AEX_Isn == this.selectedSchedule
          )

          const covenant = Object.assign(
            {
              name: schedule.Convenio.con_nomefant,
              plan: schedule.PlanoConvenio.plc_nome,
            },
            this.$store.state.checkin.form.covenant
          )
          this.$store.commit('checkin/updateForm', {
            id: checkinId,
            schedule: this.selectedSchedule,
            step: 3,
            covenant: covenant,
          })
        } catch (error) {
          this.$toast.show('Erro ao salvar checkin', 'warn')
          throw error
        } finally {
          this.loading = false
        }
      } else {
        this.$toast.show('Selecione um exame', 'warn')
      }
    },

    changeSelected(evt) {
      this.selectedSchedule = evt.target.checked ? evt.target.value : null
    },

    _loadData() {
      this.selectedSchedule = this.$store.state.checkin.form.schedule
      if (this.selectedSchedule == null && this.schedules.length == 1) {
        this.selectedSchedule = this.schedules[0].AEX_Isn
      }
    },

    _isValid() {
      let valid = this.selectedSchedule != null
      if (!valid) {
        const errorMsg = 'Selecione um exame'
        if (this.errorMessages.indexOf(errorMsg) === -1) {
          this.errorMessages.push(errorMsg)
        }

        this.$scrollTop()
      }
      return valid
    },

    getDay(date) {
      return this.$moment(date, 'DD/MM/YYYY').format('DD')
    },

    getMonth(date) {
      return this.$moment(date, 'DD/MM/YYYY').format('MMMM')
    },

    getHour(schedule) {
      let hours = schedule.examesAgendados.map(exam => {
        return exam.PAX_HoraSeq
      })
      let hour = hours.sort()[0]
      return _.isEmpty(hour) ? null : this.$moment(hour, 'HHmm').format('HH:mm')
    },
  },
}
</script>
<style lang="scss" scoped>
.date {
  display: flex;
  align-items: center;
  .day {
    font-size: 2rem;
    margin-right: 1rem;
  }
  .details {
    display: flex;
    flex-direction: column;
  }
}
</style>
